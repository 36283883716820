.catalogModal {
  height: 600px;
  padding: 10px;
  .box {
    background: #fff;
    
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: solid #F3F3F3;
      border-width: 1px 0;
      .acronym {
        display: flex;
        align-items: center;
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
        img {
          height: 38px;
        }
        h6 {
          font-size: 14px;
          font-weight: normal;
        }
        ul {
          display: flex;
          li {
            margin-right: 10px;
            cursor: pointer;
            font-size: 14px;
            &.active {
              color: #2b99ff;
            }
            &:hover {
              color: #2b99ff;
            }
          }
        }
      }
      .type {
        label {
          margin-left: 12px;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    
    .brands {
      height: 550px;
      overflow-y: auto;
      ul {
        display: flex;
        flex-wrap: wrap;
      }
      li {
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        border: 1px solid #F3F3F3;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 10px 10px 0 0;
        border-radius: 3px;
        cursor: pointer;
        &:hover {
          box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.1);
        }
        img {
          height: 60%;
        }
        p {
          text-align: center;
          word-break: break-all;
          padding: 0;
        }
      }
    }
    .model {
      height: 550px;
      display: flex;
      .item {
        height: 100%;
        flex: 1 1;
        margin-right: 2px;
        border: 1px solid #F3F3F3;
        .edit {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          height: 36px;
          text-align: right;
          border-top: 1px solid #F3F3F3;
        }
        h5 {
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          padding: 0 16px;
          background: #f4f3f3;
          font-weight: normal;
        }
        input {
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          width: 94%;
          margin: 6px auto;
          padding: 0 6px;
          display: block;
          border: 1px solid #F3F3F3;
        }
        ul {
          width: 100%;
          height: calc(100% - 116px);
          overflow-y: auto;
          li {
            padding: 10px 16px;
            cursor: pointer;
            font-size: 14px;
            &:hover {
              color: #2b99ff;
            }
            &:nth-child(even) {
              background: #F9FBFF;
            }
            &.active {
              background: #2b99ff;
              color: #fff;
            }
            &.grey {
              color: #b6b5b5;
            }
            span {
              display: inline-block;
              position: relative;
              .guide {
                position: absolute;
                top: 0;
                right: -20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: rgba(43,153,255, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                i {
                  width: 14px;
                  height: 14px;
                  border-radius: 50%;
                  background: #2B99FF;
                  display: block;
                }
                div {
                  width: 100px;
                  height: 36px;
                  line-height: 36px;
                  background: #1A90FF;
                  border-radius: 4px;
                  position: absolute;
                  top: 38px;
                  left: 0;
                  font-size: 14px;
                  color: #ffffff;
                  text-align: center;
                  em {
                    position: absolute;
                    top: -18px;
                    left: 7px;
                    color: #1A90FF;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}