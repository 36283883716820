body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1,
h2,
h3,
h4,
h5,
h6,
div,
dl,
dt,
dd,
ul,
ol,
li,
p,
blockquote,
pre,
hr,
figure,
table,
caption,
th,
td,
form,
fieldset,
legend,
input,
button,
textarea {
  margin: 0;
  padding: 0;
  font-size: 14px;
  box-sizing: border-box;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}

.relative {
  position: relative;
}

$pxList: [2, 5, 8, 10, 12, 15, 20, 30];
// 外边距
@each $i in $pxList {
	.m#{$i} {
		margin: $i + px;
	}
	.mr#{$i} {
		margin-right: $i + px;
	}
	.ml#{$i} {
		margin-left: $i + px;
	}
	.mt#{$i} {
		margin-top: $i + px;
	}
	.mb#{$i} {
		margin-bottom: $i + px;
	}
	.mlr#{$i} {
		margin-left: $i + px;
		margin-right: $i + px;
	}
	.mtb#{$i} {
		margin-top: $i + px;
		margin-bottom: $i + px;
	}
}

// 内边距
@each $i in $pxList {
	.p#{$i} {
		padding: $i + px;
	}
	.pr#{$i} {
		padding-right: $i + px;
	}
	.pl#{$i} {
		padding-left: $i + px;
	}
	.pt#{$i} {
		padding-top: $i + px;
	}
	.pb#{$i} {
		padding-bottom: $i + px;
	}
	.plr#{$i} {
		padding-left: $i + px;
		padding-right: $i + px;
	}
	.ptb#{$i} {
		padding-top: $i + px;
		padding-bottom: $i + px;
	}
}

.br4 {
  border-radius: 4px;
}


.b {
  font-weight: bold;
}
.fs12 {
  font-size: 12px;
}
.fs14 {
  font-size: 14px;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}
.flex-s-c {
  display: flex;
  align-items: center;
}
.flex-b {
  display: flex;
  justify-content: space-between;
}
.flex-b-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.p-color {
  color: #096BED;
}
.d-color {
  color: #FF7A00;
}
.s-color {
  color: #08A728;
}
.p-b-color {
  background-color: #EDF4FE;
}

.pointer {
  cursor: pointer;
}
.no-click {
  cursor: auto;
  opacity: .5;
}

.b {
  font-weight: 600;
}
.red {
  color: red;
}
.bgc-f8 {
  background-color: #f8f8f8;
}
.bgc-fff {
  background-color: #fff;
}

.border {
  border: 1px solid #DDDDDD;
}

.threed-con {
  width: 100%;
  .data-top-box {
    @extend .flex-s-c, .br4, .plr15;
    height: 58px;
    background-color: #fff;
    color: #333333;
    .box-item {
      @extend .flex-c-c, .br4, .mr15;
      height: 38px;
      padding: 0 20px 0 10px;
    }
    .f0 {
      background-color: #EBF1FA;
      .i {
        color: #096BED;
      }
    }
    .f1 {
      background-color:#E9FAEC;
      .i {
        color:#08A728;
      }
    }
    .f2 {
      background-color: #FBF2E8;
      .i {
        color:#FF7A00;
      }
    }
  }
}

.brand-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  width: 32px;
  height: 20px;
  border: 1px solid #E5E5E5;
  .img {
    max-width: 30px;
    max-height: 18px;
  }
}

.min-content-height1 {
  min-height: calc(100vh - 60px);
}


.del-x-spreadsheet-total {
  .x-spreadsheet-total {
    display: none;
  }
}

.x-spreadsheet-scrollbar.vertical  {
  width: 12px !important;
}
.x-spreadsheet-scrollbar.horizontal {
  height: 12px !important;
}

.x-spreadsheet-item.state {
  word-break: break-all !important;
  font-size: 13px !important;
}

.apple {
  .ant-modal {
    transition: all  0.5s;
  }
}

.icon-close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  color: red;
  font-size: 16px;
  z-index: 999;
} 

.hover {
  &:hover {
    background-color: rgba($color: #096BED, $alpha: .1);
  }
}
